import Vue from "vue";
import VueRouter from "vue-router";
// @ts-ignore
import { authGuard, signupKeyGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/entry/:id",
    name: "signup",
    component: () => import("../views/signup/Index.vue"),
  },
  {
    path: "/signup/terms",
    name: "signup_terms",
    component: () => import("../views/signup/Terms.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/select",
    name: "signup_select",
    component: () => import("../views/signup/SelectRegistInfo.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/email/create",
    name: "signup_email_create",
    component: () => import("../views/signup/EmailCreate.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/profile/create",
    name: "signup_profile_create",
    component: () => import("../views/signup/ProfileCreate.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/email/authorize",
    name: "signup_email_authorize",
    component: () => import("../views/signup/EmailAuthorize.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/phone/create",
    name: "signup_phone_create",
    component: () => import("../views/signup/PhoneCreate.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/phone/authorize",
    name: "signup_phone_authorize",
    component: () => import("../views/signup/PhoneAuthorize.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/signup/confirm",
    name: "signup_confirm",
    component: () => import("../views/signup/Confirm.vue"),
    beforeEnter: signupKeyGuard,
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/messages",
    name: "messages_index",
    component: () => import("../views/messages/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/messages/:id",
    name: "messages_show",
    component: () => import("../views/messages/Show.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/attendances",
    name: "attendances_index",
    component: () => import("../views/attendances/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/attendances/create",
    name: "attendances_create",
    component: () => import("../views/attendances/Create.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/attendances/:id/edit",
    name: "attendances_edit",
    component: () => import("../views/attendances/Create.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/attendances/confirm",
    name: "attendances_confirm",
    component: () => import("../views/attendances/Confirm.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/temperature",
    name: "temperature_index",
    component: () => import("../views/temperature/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/temperature/history",
    name: "temperature_history",
    component: () => import("../views/temperature/History.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/schedules",
    name: "schedules_index",
    component: () => import("../views/schedules/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/schedules/weekly",
    name: "schedules_weekly",
    component: () => import("../views/schedules/Weekly.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/schedules/create",
    name: "schedules_create",
    component: () => import("../views/schedules/Create.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/schedules/:id/edit",
    name: "schedules_edit",
    component: () => import("../views/schedules/Create.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/settings/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/guardian",
    name: "settings_guardian",
    component: () => import("../views/settings/guardian/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/guardian/profile/edit",
    name: "guardian_profile_edit",
    component: () => import("../views/settings/guardian/ProfileEdit.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/guardian/email/edit",
    name: "guardian_email_edit",
    component: () => import("../views/settings/guardian/EmailEdit.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/guardian/phone/edit",
    name: "guardian_phone_edit",
    component: () => import("../views/settings/guardian/PhoneEdit.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/guardian/password/edit",
    name: "guardian_password_edit",
    component: () => import("../views/settings/guardian/PasswordEdit.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/guardian/enquete/edit",
    name: "guardian_enquete_edit",
    component: () => import("../views/settings/guardian/EnqueteEdit.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/children",
    name: "settings_children",
    component: () => import("../views/settings/children/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/children/:index",
    name: "children_show",
    component: () => import("../views/settings/children/Show.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/settings/children/:index/profile/edit",
    name: "children_edit",
    component: () => import("../views/settings/children/ProfileEdit.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/qrlerror",
    name: "qrlerror",
    component: () => import("../views/QrlError.vue"),
  },
  {
    path: "/message/:id",
    name: "redirect",
    component: () => import("../Redirect.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/forum",
    name: "forum",
    component: () => import("../views/forums/Index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/forum/:id",
    name: "forumShow",
    component: () => import("../views/forums/Show.vue"),
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
