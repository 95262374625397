import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    guardian: {},
    isFirstGuardian: false,
    schoolPlan: "light",
    children: [],
    selectedChildrenIndex: 0,
    attendancePayload: {},
    attendances: [],
    attendancesCount: 0,
    messages: [],
    messagesCount: 0,
    schedulesForCalendar: [],
    eventsForCalendar: [],
    attendancesForCalendar: [],
    schedulesAndEvents: [],
    schedulesAndEventsCount: [],
    studentWithGuardian: [],
    temporarySchedule: {},
    signupData: {},
    signupTargetStudentId: null,
    signupKey: null,
    existingGuardian: [],
    passwordEditOnly: false,
    attendanceReason: null,
    loadingMessage: "",
    loading: false,
    statusMessage: "",
    status: false,
  },
  mutations: {
    setStatus(state, payload) {
      state.status = payload;
    },
    setStatusMessage(state, payload) {
      state.statusMessage = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setLoadingMessage(state, payload) {
      state.loadingMessage = payload;
    },
    setChildren(state, payload) {
      state.children = payload;
    },
    setGuardian(state, payload) {
      state.guardian = payload;
    },
    setSchoolPlan(state, payload) {
      state.schoolPlan = payload;
    },
    setSelectedChildrenIndex(state, payload) {
      state.selectedChildrenIndex = payload;
    },
    setAttendances(state, payload) {
      state.attendances = payload;
    },
    setAttendancesCount(state, payload) {
      state.attendancesCount = payload;
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    setMessagesCount(state, payload) {
      state.messagesCount = payload;
    },
    setSchedulesForCalendar(state, payload) {
      state.schedulesForCalendar = payload;
    },
    setEventsForCalendar(state, payload) {
      state.eventsForCalendar = payload;
    },
    setAttendancesForCalendar(state, payload) {
      state.attendancesForCalendar = payload;
    },
    setSchedulesAndEvents(state, payload) {
      state.schedulesAndEvents = payload;
    },
    setSchedulesAndEventsCount(state, payload) {
      state.schedulesAndEventsCount = payload;
    },
    setStudentWithGuardian(state, payload) {
      state.studentWithGuardian = payload;
    },
    setAttendancePayload(state, payload) {
      state.attendancePayload = payload;
    },
    setSignupData(state, payload) {
      state.signupData = payload;
    },
    mergeSignupData(state, payload) {
      state.signupData = Object.assign(state.signupData, payload);
    },
    setSignupTargetStudentId(state, payload) {
      state.signupTargetStudentId = payload;
    },
    setSignupKey(state, payload) {
      state.signupKey = payload;
    },
    setExistingGuardian(state, payload) {
      state.existingGuardian = payload;
    },
    setIsFirstGuardian(state, payload) {
      state.isFirstGuardian = payload;
    },
    setPasswordEditOnly(state, payload) {
      state.passwordEditOnly = payload;
    },
    setAttendanceReason(state, payload) {
      state.attendanceReason = payload;
    },
  },
  actions: {
    setStatus({ commit }, payload) {
      commit("setStatus", payload);
    },
    setStatusMessage({ commit }, payload) {
      commit("setStatusMessage", payload);
    },
    setLoading({ commit }, payload) {
      commit("setLoading", payload);
    },
    setLoadingMessage({ commit }, payload) {
      commit("setLoadingMessage", payload);
    },
    setChildren({ commit }, payload) {
      commit("setChildren", payload);
    },
    setGuardian({ commit }, payload) {
      commit("setGuardian", payload);
    },
    setSchoolPlan({ commit }, payload) {
      commit("setSchoolPlan", payload);
    },
    setSelectedChildrenIndex({ commit }, payload) {
      commit("setSelectedChildrenIndex", payload);
    },
    setAttendances({ commit }, payload) {
      commit("setAttendances", payload);
    },
    setAttendancesCount({ commit }, payload) {
      commit("setAttendancesCount", payload);
    },
    setMessages({ commit }, payload) {
      commit("setMessages", payload);
    },
    setMessagesCount({ commit }, payload) {
      commit("setMessagesCount", payload);
    },
    setSchedulesForCalendar({ commit }, payload) {
      commit("setSchedulesForCalendar", payload);
    },
    setEventsForCalendar({ commit }, payload) {
      commit("setEventsForCalendar", payload);
    },
    setAttendancesForCalendar({ commit }, payload) {
      commit("setAttendancesForCalendar", payload);
    },
    setSchedulesAndEvents({ commit }, payload) {
      commit("setSchedulesAndEvents", payload);
    },
    setSchedulesAndEventsCount({ commit }, payload) {
      commit("setSchedulesAndEventsCount", payload);
    },
    setStudentWithGuardian({ commit }, payload) {
      commit("setStudentWithGuardian", payload);
    },
    setAttendancePayload({ commit }, payload) {
      commit("setAttendancePayload", payload);
    },
    setSignupData({ commit }, payload) {
      commit("setSignupData", payload);
    },
    mergeSignupData({ commit }, payload) {
      commit("mergeSignupData", payload);
    },
    setSignupTargetStudentId({ commit }, payload) {
      commit("setSignupTargetStudentId", payload);
    },
    setSignupKey({ commit }, payload) {
      commit("setSignupKey", payload);
    },
    setExistingGuardian({ commit }, payload) {
      commit("setExistingGuardian", payload);
    },
    setIsFirstGuardian({ commit }, payload) {
      commit("setIsFirstGuardian", payload);
    },
    setPasswordEditOnly({ commit }, payload) {
      commit("setPasswordEditOnly", payload);
    },
    setAttendanceReason({ commit }, payload) {
      commit("setAttendanceReason", payload);
    },
  },
  modules: {},
});
