import Vue from "vue";
import axios from "axios";
import { logout } from "@/lib/util";

export const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export const getData = (target: any, payload?: any) => {
  const request = (token: string) => {
    const p = (t: string) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(t, {
            params: payload,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response: { data: unknown }) => {
            resolve(response.data);
          })
          .catch((error: any) => {
            console.log(error);
            if (error.response && error.response.status === 401) {
              logout();
            }
            reject(error);
          });
      });
    };
    if (typeof target === "string") {
      return p(target);
    } else if (Array.isArray(target)) {
      return Promise.all(target.map((t) => p(t)));
    } else {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
  };
  return Vue.prototype.$auth.getTokenSilently().then((token: string) => {
    return request(token);
  });
};

export const createData = (target: any, payload: any, extraHeader = null) => {
  return Vue.prototype.$auth.getTokenSilently().then((token: string) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(target, payload, Object.assign(requestHeader, extraHeader))
        .then((response: { data: unknown }) => {
          resolve(response.data);
        })
        .catch((error: { response: { status: number } }) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            logout();
          }
          reject(error);
        });
    });
  });
};

export const updateData = (target: any, payload: any, extraHeader = null) => {
  return Vue.prototype.$auth.getTokenSilently().then((token: string) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(target, payload, Object.assign(requestHeader, extraHeader))
        .then((response: { data: unknown }) => {
          resolve(response.data);
        })
        .catch((error: { response: { status: number } }) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            logout();
          }

          reject(error);
        });
    });
  });
};

export const updateDataForChild = (
  target: any,
  payload: any,
  extraHeader = null
) => {
  return Vue.prototype.$auth.getTokenSilently().then((token: string) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-HTTP-Method-Override": "PUT",
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(target, payload, Object.assign(requestHeader, extraHeader))
        .then((response: { data: unknown }) => {
          resolve(response.data);
        })
        .catch((error: { response: { status: number } }) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            logout();
          }

          reject(error);
        });
    });
  });
};

export const deleteData = (target: any) => {
  return Vue.prototype.$auth.getTokenSilently().then((token: any) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete(target, requestHeader)
        .then((response: { data: unknown }) => {
          resolve(response.data);
        })
        .catch((error: { response: { status: number } }) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            logout();
          }

          reject(error);
        });
    });
  });
};
