import { defineStore } from "pinia";
import { ref } from "vue";

export const useSchoolPlanStore = defineStore("schoolPlan", () => {
  /** 生徒の学校から抽出した保護者の使える機能 1人でも使える生徒がいればtrueになる */
  const schoolPlan = ref<SchoolPlan>({});

  const isGovernmentSchool = ref(false);

  const isPlanStandard = ref(false);

  return { schoolPlan, isGovernmentSchool, isPlanStandard };
});
