var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', {
    attrs: {
      "id": "app"
    }
  }, [_vm.$store.state.status ? _c('status-bar') : _vm._e(), _vm.$store.state.loading ? _c('div', {
    attrs: {
      "id": "loading"
    }
  }, [_c('scaling-squares-spinner', {
    attrs: {
      "animation-duration": 1200,
      "size": 60,
      "color": "#ab001c"
    }
  }), _c('div', {
    staticClass: "message"
  }, [_vm._v(" " + _vm._s(_vm.$store.state.loadingMessage ? _vm.$store.state.loadingMessage : "Loading...") + " ")])], 1) : _vm._e(), _c('transition', [_c('router-view')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };